<template>
    <div>
        <jy-query ref="checkForm" :model="formData">
            <jy-query-item label="日期" prop="oDate">
                <el-date-picker v-model="formData.oDate" value-format="yyyy-MM-dd" type="date" placeholder="选择日期">
                </el-date-picker>
            </jy-query-item>
            <jy-query-item label="车牌号" prop="vehicleNo">
                <el-input placeholder="请输入" v-model="formData.vehicleNo"></el-input>
            </jy-query-item>
            <jy-query-item label="自编号" prop="customNo">
                <el-input placeholder="请输入" v-model="formData.customNo"></el-input>
            </jy-query-item>
            <jy-query-item label="司机" prop="driverNa">
                <el-input placeholder="请输入" v-model="formData.driverNa"></el-input>
            </jy-query-item>
            <jy-query-item label="运行情况" prop="eState">
                <el-select placeholder="请选择" v-model="formData.eState">
                    <el-option v-for="item in runStatus" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="审核状态" prop="verify">
                <el-select placeholder="请选择" v-model="formData.verify">
                    <el-option v-for="item in auditStatus" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="线路" prop="routeIds" :span="1.6">
                <el-select v-model="formData.routeIds" placeholder="请选择" multiple filterable>
                    <el-option v-for="item in routeList" :key="item.routeId" :label="item.cName" :value="item.routeId"></el-option>
                </el-select>
            </jy-query-item>
            <template #rightCol>
                <el-button type="primary" v-on:click="search" v-if="btnexist('scheduleRoadBillInfoAuditList')">查询</el-button>
                <el-button type="primary" v-on:click="reset" v-if="btnexist('scheduleRoadBillInfoAuditReset')">重置</el-button>
                <el-row>
                    <el-button type="primary" v-on:click="batchaudit" v-if="btnexist('scheduleRoadBillInfoAuditBatchVerity')">批量审核</el-button>
                </el-row>
            </template>
        </jy-query>
        <jy-table ref="jyTable" @select-all="selectallchange" @select="selectchange" :data="dataList" :max-height="650">
            <jy-table-column type="index" label="序号" width="60"></jy-table-column>
            <jy-table-column type="selection" width="40"></jy-table-column>
            <jy-table-column prop="oDate" label="日期" width="100"></jy-table-column>
            <jy-table-column prop="routeNa" label="线路名称" min-width="100"></jy-table-column>
            <jy-table-column prop="customNo" label="自编号" width="95" show-overflow-tooltip></jy-table-column>
            <jy-table-column prop="vehicleNo" label="车牌号" width="95" show-overflow-tooltip></jy-table-column>
            <jy-table-column prop="driverNa" label="司机" width="80" show-overflow-tooltip></jy-table-column>
            <jy-table-column prop="sSiteNa" label="起点站" width="135" show-overflow-tooltip></jy-table-column>
            <jy-table-column prop="eSiteNa" label="终点站" width="135" show-overflow-tooltip></jy-table-column>
            <jy-table-column prop="sTime" label="发车时间" width="80"></jy-table-column>
            <jy-table-column prop="eTime" label="结束时间" width="80"></jy-table-column>
            <jy-table-column prop="runM" label="实际运行里程(km)" width="80" show-overflow-tooltip></jy-table-column>
            <jy-table-column prop="eState" label="运行情况" min-width="80" show-overflow-tooltip :formatter="runStatusFil"></jy-table-column>
            <jy-table-column prop="verify" label="审核状态" min-width="110" show-overflow-tooltip :formatter="auditStatusFil"></jy-table-column>
            <jy-table-column prop="remark" label="备注" min-width="120" show-overflow-tooltip></jy-table-column>
            <jy-operate :list="butList"></jy-operate>
        </jy-table>
        <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total"></jy-pagination>
        <audit ref="audit" @updateList="getList"></audit>
        <detail ref="detail"></detail>
        <track-replay ref="trackReplay"></track-replay>
    </div>
</template>
<script>
import trackReplay from "@/components/pages/admin/common/trackReplay/trackReplay";
import detail from '@/components/pages/admin/roadBillInfo/management/detail.vue'
import audit from '@/components/pages/admin/roadBillInfo/audit/audit.vue'
import { btnMixins } from '@/common/js/button.mixin'
import { getAuditStatus, getRunStatus } from '@/common/js/wordbook.js'
export default {
    data() {
        let auditStatus = getAuditStatus()
        auditStatus.unshift({value:'',label:'全部'})
        let runStatus = getRunStatus()
        return {
            btnMenuId: 'scheduleRoadBillInfoAudit',
            butList: [],
            dataList: [],
            auditStatus,
            runStatus,
            pageIndex: 1,
            pageSize: 10,
            total: 0,
            formData: {
                oDate: '',
                routeIds: [],
                customNo: '',
                vehicleNo: '',
                driverNa: '',
                eState: '',
                verify: '0',
            },
            routeList: [],
            selectList: [],
        }
    },
    components: { detail, trackReplay, audit },
    mixins: [btnMixins],
    created() {
        this.setButList()
        this.getRouteList()
    },
    activated(){
        this.getList()
    },
    methods: {
        getList() {
            let url = '/WaybillVerity/queryPage'
            let params = {
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            }
            Object.keys(this.formData).forEach(key => {
                let d = this.formData[key]
                if (key == 'routeIds') {
                    d = d.join(',')
                }
                if (!/^\s*$/.test(d)) {
                    params[key] = d
                }
            })
            this.$http.post(url, params).then(({ detail }) => {
                this.dataList = detail.list
                this.total = detail.total
            })
        },
        auditStatusFil(row, column, cellVal) {
            let label = ''
            this.auditStatus.forEach(one => {
                if (one.value == cellVal) {
                    label = one.label
                }
            })
            return label
        },
        runStatusFil(row, column, cellVal) {
            let label = ''
            this.runStatus.forEach(one => {
                if (one.value == cellVal) {
                    label = one.label
                }
            })
            return label
        },
        setButList() {
            let list = [{
                name: '完整信息',
                fun: (row) => {
                    this.$refs.detail.init(row)
                },
                isShow: () => { return this.btnexist('scheduleRoadBillInfoAuditDetail') }
            }, {
                name: '轨迹回放',
                fun: (row) => {
                    this.$refs.trackReplay.init(row, "waybill");
                },
                isShow: () => { return this.btnexist('scheduleRoadBillInfoAuditTrackPlayback') }
            }, {
                name: '审核',
                fun: (row) => {
                    this.onAudit(row)
                },
                isShow: (row) => { return this.btnexist('scheduleRoadBillInfoAuditVerity')&&row.verify==0 }
            }]
            this.butList = list
        },
        search() {
            this.getList()
        },
        reset() {
            this.$refs.checkForm.resetFields()
            this.getList()
        },
        // 分页
        handleSizeChange(val) {
            this.pageSize = val
            this.getList()
        },
        handleCurrentChange(val) {
            this.pageIndex = val
            this.getList()
        },
        // 勾选数据
        selectchange(selection) {
            this.selectList = selection
        },
        selectallchange(selection) {
            this.selectList = selection
        },
        getRouteList() {
            let url = '/route/info/queryRouteInfoByOrgIds'
            this.$http.post(url).then(({ detail }) => {
                this.routeList = detail
            })
        },
        // 批量提交审核
        batchaudit() {
            if (this.selectList.length == 0) {
                this.$message({
                    type: "warning",
                    message: "请先选取数据",
                });
            } else {
                this.$refs.audit.init(
                    this.selectList,
                    "batch"
                );
            }
        },
        // 提交审核
        onAudit(row) {
            this.$refs.audit.init(row, "single");
        },
    }
}

</script>
